// Minimum required stylesheets
import '../common/stylesheets/external-crutch.less';
import '../common/stylesheets/modules/footer/footer.less';
import '../common/stylesheets/modules/header/index.less';
import '../common/stylesheets/shapes.less';

import '../global/elements/buttons.less';
import '../global/settings/index.less';
import '../global/vendor/tailwind/tailwind.less';

import '../common/stylesheets/maintenance.css';
import '../graphicstock/modules/home/home.less';
import '../storyblocks/stylesheets/modules/home/storyblocks-home-redesign.less';
import '../storyblocks/stylesheets/modules/home/storyblocks-home-redesign-compat.less';
import '../common/stylesheets/modules/widgets/stock-item-group-widget-card-design-v2.less';
import '../common/stylesheets/modules/widgets/stock-item-group-widget-card-design.less';
import '../common/stylesheets/modules/widgets/stock-item-group-widget.less';
import '../common/stylesheets/modules/notfound/not-found.less';
import '../react/builder-io/builder-io.less';

// Minimum required javascript
import '../common/javascripts/shared/toggleExpand.js';

import '../react/common/Logger';

import AppLoader from '../react/AppLoader/AppLoader';
import EntTrialBanner from "../react/Trials/EntTrialBanner";
import BuilderClientSideRenderer from "../react/builder-io/BuilderClientSideRenderer";
import NavApps from "../react/app/NavApps";

AppLoader.load({
  routes: [],
  auxApps: {
    ...NavApps,
    'builder-io-wrapper': BuilderClientSideRenderer
  },
  propBasedApps: {
    'entTrialBanner': EntTrialBanner
  }
});
