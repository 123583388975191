import React from 'react';
import { twMerge } from 'tailwind-merge';

import { NewTag } from '@videoblocks/storywind';

import './StockItemIcons.less';

interface Props {
  showNewFlag?: boolean;
  className?: string;
  iconClassName?: string;
}

/**
 * This is used to display visual badges on a StockItem card.
 * .StockItemIcons class has pointer-events: none and will
 * pass clicks through to the stock item image, which
 * will lead users to the details URL.
 */
export default function StockItemIcons(props: Props) {
  const { showNewFlag, className, iconClassName } = props;

  if (!showNewFlag) return null;

  return (
    <div
      className={twMerge(
        'StockItemIcons absolute flex items-center z-20',
        className
      )}
    >
      <NewTag className={twMerge('text-black bg-white', iconClassName)} />
    </div>
  );
}

StockItemIcons.defaultProps = {
  showNewFlag: false,
};
